.cr-header {
  margin-bottom: $spacer * .5;
  border-bottom: $border-width solid $border-color;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

    .user-icon {
      width: 30px;
    }
  
    .user-name {
      align-self: center;
      margin-right: 5px;
      color: #6A82FB;
    }
}
