.card-img-left {
  border-radius: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.card-img-right {
  border-radius: 0;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card .description {
  color: DarkGray;
  font-size: 0.6rem;
  text-align: center;
  margin: 0;
}

.media .right .h4 {
  min-width: 2em;
}

.media .more-right .h4 {
  min-width: 2em;
  opacity: 0.3;
}